import React from "react";
import { ArrowRightShort } from "react-bootstrap-icons";

import ContactForm from "../ContactForm";
import { colors } from "../../theme";
import { services } from "../../pages/services/ServicesPage";
import NavbarBrand from "./NavbarBrand";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";

const Navbar = () => {
  const links = [
    {
      id: 1,
      label: "Approach",
      link: "/approach",
    },
    // {
    //   id: 2,
    //   label: "Blog",
    //   link: "/blog",
    // },
    {
      id: 3,
      label: "About us",
      link: "/about-us",
    },
    {
      id: 4,
      label: "Contact",
      link: "/contact-us",
    },
  ];
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light py-3"
        style={{
          borderBottom: `.2rem solid ${colors.secondary}`,
        }}
      >
        <div className="container">
          <NavbarBrand />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <NavItem isDropdown={true} label="Services" link="/services">
                <ul className="list-unstyled">
                  {services.map((s) => (
                    <li key={s.id}>
                      <Link
                        to={`/services/${s.slug}`}
                        className="nav-link fs-6"
                      >
                        <ArrowRightShort style={{ color: colors.secondary }} />
                        {s.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </NavItem>
              {links.map((link) => (
                <NavItem key={link.id} {...link} />
              ))}
              <li className="nav-item dark-nav">
                <a
                  className="nav-link"
                  href="#!"
                  data-bs-toggle="modal"
                  data-bs-target="#requestQuoteModal"
                >
                  <span className="dark-nav-text">Request Quote</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        className="modal fade"
        id="requestQuoteModal"
        tabIndex="-1"
        aria-labelledby="requestQuoteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="requestQuoteModalLabel">
                Request for a Quote
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

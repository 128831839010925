export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_REPOS = "GET_REPOS";
export const NO_REPOS = "NO_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const GET_CHALLENGES = "GET_CHALLENGES";
export const GET_CHALLENGE = "GET_CHALLENGE";
export const CHALLENGE_ERROR = "CHALLENGE_ERROR";
export const REMOVE_CHALLENGE = "REMOVE_CHALLENGE";
export const CREATE_CHALLENGE = "CREATE_CHALLENGE";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const GET_MEETINGS = "GET_MEETINGS";
export const GET_MEETING = "GET_MEETING";
export const MEETING_ERROR = "MEETING_ERROR";
export const DELETE_MEETING = "DELETE_MEETING";
export const REQUEST_FOR_MEETING = "REQUEST_FOR_MEETING";
export const GET_QUOTATIONS = "GET_QUOTATIONS";
export const GET_QUOTATION = "GET_QUOTATION";
export const QUOTATION_ERROR = "QUOTATION_ERROR";
export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const REQUEST_QUOTATION = "REQUEST_QUOTATION";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_ERROR = "USER_ERROR";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const SET_LOADING = "SET_LOADING";

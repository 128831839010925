import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronRight } from "react-bootstrap-icons";
import { requestQuotation } from "../actions/quotation";
import { connect } from "react-redux";

const ContactForm = ({ requestQuotation, loading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [description, setDescription] = useState("");
  return (
    <>
      <form
        className="mt-3"
        onSubmit={(e) => {
          e.preventDefault();
          requestQuotation({ name, email, budget, description });
          setName("");
          setEmail("");
          setBudget("");
          setDescription("");
        }}
      >
        <div className="mb-3">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control input-jast"
            id="fullname"
            placeholder="Mine's Jast Tech, what's yours?"
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Email address</label>
          <input
            type="email"
            className="form-control input-jast"
            id="email"
            placeholder="Something like jast-tech@gmail.com"
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Budget (if any)</label>
          <input
            type="text"
            className="form-control input-jast"
            id="budget"
            placeholder="We can come to an arrangement..."
            name="budget"
            required
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />
          <div className="form-text">
            <div className="d-flex">
              <ChevronRight className="mr-3" />{" "}
              <em>
                <a
                  target="_blank"
                  className="text-decoration-none text-muted"
                  href="https://www.webfx.com/How-much-should-web-site-cost.html#web-design-costs"
                  rel="noreferrer"
                >
                  Article from Webfx.com that you can refer to for budget
                  estimation.
                </a>
              </em>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Description / message / feedback
          </label>
          <textarea
            name="description"
            id=""
            cols="30"
            rows="4"
            required
            placeholder="e.g I want a mobile application that can do A, B, C, and also D"
            className="form-control input-jast"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-3 d-grid">
          <button type="submit" className="btn btn-secondary px-5 py-3">
            {loading ? (
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

ContactForm.propTypes = {
  requestQuotation: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loading: state.quotation.loading,
});

export default connect(mapStateToProps, { requestQuotation })(ContactForm);

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { login } from "../../../actions/auth";
import Footer from "../../../components/Footer";
// import logo from "../../../logo.svg"

const Signin = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to="/admin/overview" />;
  }
  return (
    <>
      <main className="form-signin mt-5 mb-5">
        <form onSubmit={onSubmit}>
          {/* <img className="mb-4" src={logo} alt="" width="72" height="57"/> */}
          <span className="fs-4">Jast Tech</span>
          <br />
          <small>Only Jast Tech Employees Allowed</small>

          <div className="form-floating mt-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="name@example.com"
              value={email}
              name="email"
              onChange={onChange}
            />
            <label for="email">Email address</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              name="password"
              onChange={onChange}
            />
            <label for="password">Password</label>
          </div>
          <button className="w-100 btn btn-lg btn-secondary" type="submit">
            Sign in
          </button>
        </form>
      </main>
      <Footer />
    </>
  );
};

Signin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Signin);

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../theme";

const Logo = styled.div`
  font-weight: 900;
  font-size: 36px;
  letter-spacing: -0.1em;
  color: ${colors.dark};
  @media (min-width: 992px) {
    margin-top: 90px;
    /* Browsers not below */
    transform: rotate(-90deg);
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
  }
`;

const Impression = styled.span`
  font-weight: 500;
  font-size: 64px;
  line-height: 75px;
  letter-spacing: -0.1em;
  color: ${colors.dark};
`;

const ImpressionTagLine = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.dark};
`;

const NavHead = styled.span`
  font-size: 24px;
  line-height: 28px;
  color: ${colors.dark};
`;

const Line = styled.hr`
  background-color: ${colors.white};
`;

const Footnote = styled.span`
  font-weight: 500;
  line-height: 19px;
  color: ${colors.light};
`;

const links = [
  {
    id: 1,
    name: "Services",
    url: "/services",
  },
  {
    id: 2,
    name: "About Us",
    url: "/about-us",
  },
  // {
  //   id: 3,
  //   name: "Testimonials",
  //   url: "/testimonials",
  // },
  // {
  //   id: 4,
  //   name: "Recent Work",
  //   url: "/recent-work",
  // },
  {
    id: 5,
    name: "Contact",
    url: "/contact-us",
  },
  // {
  //   id: 6,
  //   name: "FAQs",
  //   url: "/faqs",
  // },
  // {
  //   id: 7,
  //   name: "Signin",
  //   url: "/signin",
  // },
];
const Footer = () => {
  return (
    <div className="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-2">
            <Logo>JASTTECH</Logo>
          </div>
          <div className="col-md-6 mb-3">
            <Impression>
              You only get one chance to make a first impression.
            </Impression>
            <br />
            <br />
            <ImpressionTagLine>
              We can make it an awesome one.
            </ImpressionTagLine>
          </div>
          <div className="col-md-4">
            <NavHead>Useful Links</NavHead>
            <br />
            <ul className="nav flex-column">
              {links.map((item) => (
                <li className="nav-item" key={item.id}>
                  <Link className="nav-link text-dark" to={item.url}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Line />
        <Footnote>
          <small>
            2021 . Property of Jast Tech Ltd . Unless proven otherwise.
          </small>
        </Footnote>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react'
import Navbar from './navbar/Navbar'
import Footer from './Footer'

const Layout = ({children}) => {
    return (
        <>
         <Navbar />
         {children}
         <Footer />
        </>
    )
}

Layout.propTypes = {

}

export default Layout

import {
  GET_MEETINGS,
  GET_MEETING,
  REQUEST_FOR_MEETING,
  DELETE_MEETING,
  MEETING_ERROR,
  SET_LOADING,
} from "../actions/types";

const initialState = {
  meetings: [],
  meeting: null,
  loading: false,
  error: {},
};

function meetingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MEETINGS:
      return {
        ...state,
        meetings: payload,
        loading: false,
      };
    case GET_MEETING:
      return {
        ...state,
        meeting: payload,
        loading: false,
      };
    case REQUEST_FOR_MEETING:
      return {
        ...state,
        meetings: [payload, ...state.meetings],
        loading: false,
      };
    case DELETE_MEETING:
      return {
        ...state,
        meetings: state.meetings.filter((meeting) => meeting._id !== payload),
        loading: false,
      };
    case MEETING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default meetingReducer;

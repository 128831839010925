
import { lazy } from "react";

export const routes = [
  {
    id: 1,
    path: "/",
    label: "Home",
    component: lazy(() => import("./pages/home/HomePage")),
    exact: true,
  },
  {
    id: 2,
    path: "/projects",
    label: "Projects",
    component: lazy(() => import("./pages/projects/ProjectsPage")),
  },
  {
    id: 3,
    path: "/about-us",
    label: "About",
    component: lazy(() => import("./pages/about/AboutPage")),
  },
  {
    id: 4,
    path: "/approach",
    label: "Approach",
    component: lazy(() => import("./pages/approach/Approach")),
  },
  {
    id: 5,
    path: "/services",
    label: "Services",
    component: lazy(() => import("./pages/services/ServicesPage")),
    exact: true,
  },
  {
    id: 7,
    path: "/contact-us",
    label: "Reach Us",
    component: lazy(() => import("./pages/contact/ContactPage")),
    exact: true,
  },
  {
    id: 8,
    path: "/blog",
    label: "Blog",
    component: lazy(() => import("./pages/blog/BlogPage")),
    exact: true,
  },
  {
    id: 9,
    path: "/blog/:id",
    label: "Blog Post",
    component: lazy(() => import("./pages/blog/BlogPost")),
  },
  {
    id: 10,
    path: "/terms-and-conditions",
    label: "Terms and Conditions",
    component: lazy(() => import("./pages/toc/TOC")),
  },
  {
    id: 11,
    path: "/faqs",
    label: "FAQ",
    component: lazy(() => import("./pages/toc/FAQ")),
  },
  {
    id: 12,
    path: "/services/software-development",
    label: "Software Development",
    component: lazy(() => import("./pages/services/SoftwareDevelopment")),
  },
  {
    id: 13,
    path: "/services/mobile-app-development",
    label: "Mobile Application Development",
    component: lazy(() => import("./pages/services/MobileAppDevelopment")),
  },
  {
    id: 14,
    path: "/services/web-development-services",
    label: "Web Development",
    component: lazy(() => import("./pages/services/WebDevelopment")),
  },
  {
    id: 15,
    path: "/services/social-media-management",
    label: "Social Media Management",
    component: lazy(() => import("./pages/services/SocialMediaMgt")),
  },
  {
    id: 16,
    path: "/services/ict-consultancy",
    label: "ICT Consultancy",
    component: lazy(() => import("./pages/services/ICTConsultancy")),
  },
  {
    id: 19,
    path: "/admin/customers",
    label: "Customers",
    component: lazy(() => import("./pages/admin/customers/Customers")),
    exact: true,
  },
  {
    id: 20,
    path: "/admin/meetings",
    label: "Meetings",
    component: lazy(() => import("./pages/admin/meetings/Meetings")),
    exact: true,
  },
  {
    id: 21,
    path: "/admin/quotes",
    label: "Quotations",
    component: lazy(() => import("./pages/admin/QuoteRequests")),
    exact: true,
  },
  {
    id: 22,
    path: "/admin/overview",
    label: "Dashboard",
    component: lazy(() => import("./pages/admin/dashboard/Dashboard")),
    exact: true,
  },
  {
    id: 23,
    path: "/admin/projects",
    label: "Projects",
    component: lazy(() => import("./pages/admin/projects/Projects")),
    exact: true,
  },
  {
    id: 24,
    path: "/admin/members",
    label: "Members",
    component: lazy(() => import("./pages/admin/members/Members")),
    exact: true,
  },
  {
    id: 25,
    path: "/admin/billing",
    label: "Billing",
    component: lazy(() => import("./pages/admin/billing/Billing")),
    exact: true,
  },
  {
    id: 27,
    path: "/admin/articles",
    label: "Articles",
    component: lazy(() => import("./pages/admin/articles/Articles")),
    exact: true,
  },
  {
    id: 28,
    path: "/admin/legal",
    label: "Legal",
    component: lazy(() => import("./pages/admin/legal/Legal")),
    exact: true,
  },
  {
    id: 29,
    path: "/project-tracker",
    label: "Project Tracker",
    component: lazy(() => import("./pages/admin/projects/Projects")),
    exact: true,
  },
  {
    id: 30,
    path: "/admin/challenges",
    label: "Challenges",
    component: lazy(() => import("./pages/admin/challenges/Challenges")),
    exact: true,
  },
  {
    id: 31,
    path: "/admin/articles/new",
    label: "Create new article",
    component: lazy(() => import("./pages/admin/articles/NewArticle")),
  },
];

import React from "react";
import error404 from "../assets/images/error404.svg";
import Layout from "../components/Layout";

const NotFound = () => {
  return (
    <Layout>
    <div className="container my-2 d-flex justify-content-center">
      <img src={error404} alt="" className="w-50" />
      {/* <h3>Sorry pal, looks like you got lost.</h3>
      <span>
        <a href="/">Go back home</a>
      </span>
      <div className="space"></div> */}
    </div>
    </Layout>
  );
};

export default NotFound;

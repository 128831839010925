import api from "../utils/api";
import { setAlert } from "./alert";
import {
  GET_QUOTATIONS,
  QUOTATION_ERROR,
  DELETE_QUOTATION,
  REQUEST_QUOTATION,
  GET_QUOTATION,
  SET_LOADING,
} from "./types";

// Get quotations
export const getQuotations = () => async (dispatch) => {
  try {
    const res = await api.get("/quotations");

    dispatch({
      type: GET_QUOTATIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: QUOTATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete quotation
export const deletePost = (id) => async (dispatch) => {
  try {
    await api.delete(`/quotations/${id}`);

    dispatch({
      type: DELETE_QUOTATION,
      payload: id,
    });

    dispatch(setAlert("Quotation Removed", "success"));
  } catch (err) {
    dispatch({
      type: QUOTATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Request quotation
export const requestQuotation = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING,
    });
    const res = await api.post("/quotations", formData);
    const res2 = await api.post("/users/quotation-request", formData);

    dispatch({
      type: REQUEST_QUOTATION,
      payload: res.data,
    });

    dispatch(setAlert(res.data.msg, "success"));
  } catch (err) {
    dispatch({
      type: QUOTATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get quotation
export const getQuotation = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/quotations/${id}`);

    dispatch({
      type: GET_QUOTATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: QUOTATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

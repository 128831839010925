import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import { Theme } from "./theme";
import NotFound from "./pages/NotFound";
import { Suspense, useEffect, useState } from "react";
import cookies from "./assets/images/cookies.svg";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import { LOGOUT } from "./actions/types";
import Signin from "./pages/admin/auth/Signin";
import Spinner from "./components/Spinner"
import Alert from "./components/Alert";

function App() {
  const [isShown, setIsShown] = useState(false);

  const closeModal = () => {
    setIsShown(false);
  };

  useEffect(() => {
    function setCookies() {
      if (!sessionStorage.cookiesPopup) {
        const timer = setTimeout(() => {
          setIsShown(true);
          sessionStorage.cookiesPopup = 1;
        }, 5000);

        return () => clearTimeout(timer);
      }
    }
    function setReviews() {
      if (!sessionStorage.reviewsPopup) {
        const timer = setTimeout(() => {
          setIsShown(true);
          sessionStorage.reviewsPopup = 1;
        }, 7000);

        return () => clearTimeout(timer);
      }
    }
    setReviews();
    setCookies();
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Alert />
      {isShown ? (
        <>
          {/* <div className="reviewsPopup py-4 px-4 shadow animate__animated animate__bounceIn">
          <img src={reviews} alt="" className="reviewsPopup__img" />
          <br />
            <span className="text-white">Our devs would like a review if you don't mind</span>
            <form action="">
              <div className="mb-3">
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" />
              </div><div className="mb-3 d-grid">
               <button className="btn btn-light">Submit</button>
              </div>
            </form>
          </div> */}
        </>
      ) : null}
      <Suspense fallback={<Spinner />}>
        <Router>
          <Switch>
            {routes.map((route) => {
              return <Route key={route.id} {...route} />;
            })}
            <Route path="/signin" exact component={Signin} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>

      <Theme />
      {isShown ? (
        <div className="cookies py-4 px-4 shadow animate__animated animate__bounceIn">
          <img src={cookies} alt="" className="cookies-img" />
          <br />
          <span className="text-white">
            We care about your data, and we'd love to use cookies to make your
            experience better.
          </span>
          <br />
          <div className="d-flex justify-content-between">
            <button
              className="btn text-light"
              style={{ width: "150px", height: "50px" }}
            >
              Privacy Policy
            </button>
            <button
              className="btn btn-light rounded"
              style={{ width: "100px", height: "50px" }}
              onClick={() => closeModal()}
            >
              Ok
            </button>
          </div>
        </div>
      ) : null}
    </Provider>
  );
}

export default App;

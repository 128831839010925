import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const NavItem = ({ label, link, isDropdown, children, notifications }) => {
  return (
    <>
      <li className={`nav-item ${isDropdown ? `dropdown` : ``}`}>
        <Link className="nav-link" to={link}>
          <span className={notifications ? `text-success` : ``}>{label}</span>
          {notifications ? <span className="badge bg-success rounded-circle">3</span> : ''}
        </Link>
        {isDropdown ? (
          <div className="dropdown-menu dropdown-menu-end fade-down mt-4 shadow dropdown-large">
            <div className="row g-3">
              <div className="col-12">{children}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </li>
    </>
  );
};

NavItem.propTypes = {
    isDropdown: PropTypes.bool,
    notifications: PropTypes.bool
};

export default NavItem;

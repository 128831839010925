import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../theme";
import logo from "../../assets/images/logo.svg";

const NavbarBrand = (props) => {
  const Logo = styled.span`
    font-weight: 900;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.1em;
    color: ${colors.black};
  `;

  return (
    <>
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="jast tech" style={{ width: '40%' }} />
      </Link>
    </>
  );
};

NavbarBrand.propTypes = {};

export default NavbarBrand;

export const colors = {
  primary: "#92e3a9",
  secondary: "#ff725e",
  success: "#5cb85c",
  info: "#5bc0de",
  warning: "#f0ad4e",
  danger: "#d9534f",
  light: "#f5f5f5",
  dark: "#343a40",
  black: "#000",
  white: "#fff",
  gray: "#f5f5f5",
  transparent: "rgba(0, 0, 0, 0)",
};

export function Theme() {
  return (
    <>
      <style jsx="true" global="true">
        {`
          :root {
            --color-primary: ${colors.primary};
            --color-secondary: ${colors.secondary};
            --color-success: ${colors.success};
            --color-info: ${colors.info};
            --color-warning: ${colors.warning};
            --color-danger: ${colors.danger};
            --color-light: ${colors.light};
            --color-dark: ${colors.dark};
            --color-black: ${colors.black};
            --color-white: ${colors.white};
            --color-gray: ${colors.gray};
            --color-transparent: ${colors.transparent};
            
          }
        `}
      </style>
    </>
  );
}

import React from "react";
import {
  ArrowRightCircleFill,
} from "react-bootstrap-icons";
import service from "../../assets/images/service.svg";
import mobile from "../../assets/images/services/mobile.svg";
import social from "../../assets/images/services/social.svg";
import software from "../../assets/images/services/software.svg";
import web from "../../assets/images/services/web.svg";
import Layout from "../../components/Layout";
import { colors } from "../../theme";

export const services = [
  {
    id: 1,
    title: "Software Development",
    text: "Seek ways to reduce costs, improve your business processes and add value to your marketing and communications strategies.",
    image: software,
    link: "/services/software-development",
    slug: "software-development",
  },
  {
    id: 2,
    title: "Mobile App Development",
    text: "With Mobile technology taking center stage in today’s world, organizations are keen on making significant advancements in the mobile space.",
    image: mobile,
    link: "/services/mobile-app-development",
    slug: "mobile-app-development",
  },
  {
    id: 3,
    title: "Web Development Services",
    text: "A strong online presence is an effective medium of displaying your product and services among your target audience.",
    image: web,
    link: "/services/web-development-services",
    slug: "web-development-services",
  },
  {
    id: 4,
    title: "Social Media Management",
    text: "Social media is a powerful tool for marketing and communication. It allows you to reach a wide audience and increase your brand visibility.",
    image: social,
    link: "/services/social-media-management",
    slug: "social-media-management",
  },
  // {
  //   id: 5,
  //   title: "ICT Consultancy",
  //   text: "We provide our clients with the tools they need to manage their IT infrastructure and to ensure that their IT systems are in good order and working.",
  //   image: consult,
  //   link: "/services/ict-consultancy",
  //   slug: "ict-consultancy",
  // },
  // {
  //   id: 6,
  //   title: "Search Engine Optimization",
  //   text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur",
  //   image: seo,
  //   link: "/services/search-engine-optimization",
  //   slug: "search-engine-optimization",
  // },
  // {
  //   id: 7,
  //   title: "Database Management",
  //   text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur",
  //   image: database,
  //   link: "/services/database-management",
  //   slug: "database-management",
  // },
  // {
  //   id: 8,
  //   title: "Data Analytics",
  //   text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur",
  //   image: analytics,
  //   link: "/services/data-analytics",
  //   slug: "data-analytics",
  // },
];
const ServicesPage = () => {
  return (
    <Layout>
      <div className="container mt-4">
        <div className="row mb-4">
          <div className="col-md-6 pt-3">
            <div className="space"></div>
            <span className="fs-1 text-secondary">Services</span>
            <br />
            <span className="fs-4" style={{ color: colors.dark }}>
              Expertise, thorough knowledge and firsthand experience along with
              strong Industry specific research skills help us in analyzing,
              designing and implementing world class solutions.
            </span>
          </div>
          <div className="offset-md-1 col-md-5">
            <img src={service} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="row mb-4">
          {services.map((service) => (
            <a
              href={`/services/${service.slug}`}
              className="text-decoration-none col-md-3 mb-5"
              key={service.id}
            >
              <div className="card shadow">
                <img src={service.image} alt="" className="card-img-top" />
                <div className="card-body">
                  <h5
                    className="card-title text-secondary text-center text-uppercase"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {service.title}
                  </h5>
                  <div className="card_text_reveal text-light reveal p-5">
                    {service.text}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
        <div className="my-5">
          <div className="container d-flex justify-content-between">
            <div className="solid__left">
              <strong className="fs-3 text-secondary">
                Looking for our expert software
                <br /> development services?
              </strong>
              <div className="d-flex justify-content-between">
                <button className="btn">
                  Talk to our Experts!{" "}
                  <ArrowRightCircleFill className="cool__icon mx-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;

import {
  DELETE_QUOTATION,
  GET_QUOTATION,
  GET_QUOTATIONS,
  QUOTATION_ERROR,
  REQUEST_QUOTATION,
  SET_LOADING,
} from "../actions/types";

const initialState = {
  quotations: [],
  quotation: null,
  loading: false,
  error: {},
};

function quotationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_QUOTATIONS:
      return {
        ...state,
        quotations: payload,
        loading: false,
      };
    case GET_QUOTATION:
      return {
        ...state,
        quotation: payload,
        loading: false,
      };
    case REQUEST_QUOTATION:
      return {
        ...state,
        quotations: [payload, ...state.quotations],
        loading: false,
      };
    case DELETE_QUOTATION:
      return {
        ...state,
        quotations: state.quotations.filter(
          (quotation) => quotation._id !== payload
        ),
        loading: false,
      };
    case QUOTATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default quotationReducer;
